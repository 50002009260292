import React, { Component, useEffect } from "react";
import ContextProvider from "./context/contextProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./page/HomePage";
import HomePageSeo from "./page/HomePageSeo";

import LoginPage from "./page/LoginPage";
import AccountPage from "./page/AccountPage";
import ConditionPage from "./page/ConditionPage";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Verification from "./component/Authentication/Verification";
import ProductsBySubcategoryPage from "./page/ProductsBySubcategoryPage";
import CartPage from "./page/CartPage";
import ProductDetailsPage from "./page/ProductDetailsPage";
import CategoryPage from "./page/CategoryPage";
import AccountPageApp from "./page/AccountPageApp";
import NotFoundPage from "./page/NotFoundPage";
import CheckoutPage from "./page/CheckoutPage";

import URL from "./URL";
import SearchPage from "./page/SearchPage";
import { OrderSuccessFull } from "./component/Cart/OrderSuccessfull";
import {
  Box,
  ChakraProvider,
  Flex,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";
import GetMyLocationPage from "./page/GetMyLoactionPage";
import SaveMyAddressPage from "./page/SaveMyAddress";
import Testing from "./page/Testing";
import { useContext } from "react";
import ContextData from "./context/MainContext";
import OrderDetailsPage from "./page/OrderDetailsPage";
import ProductsByCategoryPage from "./page/ProductByCategoryPage";

const App = () => {
  const { subcategories, products, keywords, seo_area, categories } =
    useContext(ContextData);

  if (subcategories.length) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/location" element={<GetMyLocationPage />} />
          <Route path="/category" element={<CategoryPage />} />
          <Route path="/search" element={<SearchPage />} />
          {subcategories.map((item, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-" +
                  (item.name + " delivery in gorakhpur")
                    .replace(/\s/g, "-")
                    .toLowerCase() +
                  "/:subcatID/:subcatName"
                }
                element={<ProductsBySubcategoryPage />}
              />
            );
          })}
          {categories.map((item, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-" +
                  (item.name + " delivery in gorakhpur")
                    .replace(/\s/g, "-")
                    .toLowerCase() +
                  "/:subcatID/:subcatName"
                }
                element={<ProductsByCategoryPage />}
              />
            );
          })}
          {products.map((item, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-" +
                  (item.product_name + " delivery in gorakhpur")
                    .replace(/\s/g, "-")
                    .toLowerCase() +
                  "/:prodID"
                }
                element={<ProductDetailsPage />}
              />
            );
          })}
          {/* USER ACCOUNT START */}
          <Route path="/orderSuccess" element={<OrderSuccessFull />} />
          <Route path="/accountApp" element={<AccountPageApp />} />
          <Route path="/orderDetails" element={<OrderDetailsPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/savemyaddress" element={<SaveMyAddressPage />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="/notification" element={<AccountPage />} />
          <Route path="/offers" element={<AccountPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/orders" element={<AccountPage />} />
          <Route path="/address" element={<AccountPage />} />
          <Route path="/condition" element={<AccountPage />} />
          <Route path="/contact" element={<AccountPage />} />
          <Route path="/about" element={<ConditionPage />} />
          <Route path="/term-and-condition" element={<ConditionPage />} />
          <Route path="/privacy-and-policy" element={<ConditionPage />} />
          <Route path="/shipping-policy" element={<ConditionPage />} />
          <Route path="/return-and-refund-policy" element={<ConditionPage />} />
          <Route path="/faq" element={<ConditionPage />} />
          <Route path="*" exact={true} element={<NotFoundPage />} />

          {/* USER ACCOUNT END */}
          {/* SEO START */}

          {keywords.map((item, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-" +
                  (item.keyword + " in gorakhpur")
                    .replace(/\s/g, "-")
                    .toLowerCase()
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      item.keyword.replace(/(^\w|\s\w)/g, (m) =>
                        m.toUpperCase()
                      ) + " in Gorakhpur"
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-grocery-delivery-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Grocery Delivery in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-grocery-delivery-app-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Grocery Delivery App in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-grocery-delivery-website-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Grocery Delivery Website in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-online-grocery-delivery-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Online Grocery Delivery in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-online-vegetables-delivery-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Online Vegetables Delivery in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-vegetables-delivery-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Vegetables Delivery in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-vegetables-delivery-app-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Vegetables Delivery App in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-vegetables-delivery-website-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Vegetables Delivery Website in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-online-fruits-delivery-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Online Fruits Delivery in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-fruits-delivery-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Fruits Delivery in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-fruits-delivery-app-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Fruits Delivery App in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {seo_area.map((areadata, i) => {
            return (
              <Route
                key={i}
                path={
                  "/wholesale-fruits-delivery-website-in-" +
                  areadata.area.replace(/\s/g, "-").toLowerCase() +
                  "-gorakhpur"
                }
                element={
                  <HomePageSeo
                    title={"Wholesale "+
                      "Fruits Delivery Website in " +
                      areadata.area +
                      " " +
                      areadata.city
                    }
                  />
                }
              />
            );
          })}

          {/* SEO END */}
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <div>
        <Box
          background={{ base: "#6bcb5b", md: "#fff" }}
          style={{ height: "100vh" }}
          className="d-flex justify-content-center"
        >
          <Box display={{ base: "block", md: "none" }}>
            <Image
              height={"100%"}
              width={"100%"}
              src="https://superg.in/admin/images/notification-images/home-loading.gif"
            />
          </Box>

          <Box display={{ base: "none", md: "block" }} mt={4}>
            <Box width={"60%"} margin="auto">
              <img
                src="./img/logo.svg"
                style={{ height: "100px", display: "block", margin: "auto" }}
              />
              <Text fontSize={24} fontWeight="800" textAlign={"center"}>
                Welcome to SuperG.in
              </Text>
            </Box>
            <Flex justifyContent={"center"} alignItems={"center"} my={20}>
              <Spinner />
            </Flex>
            <Box width={"60%"} margin="auto" mt={8}>
              <Text color="#4f5a63" textAlign={"center"}>
                The leading online vegetable, fruit, chicken and grocery home
                delivery service in Gorakhpur.
              </Text>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
};

export default App;
