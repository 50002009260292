export default URL = 'https://superg.in/admin';

export const constants = {
    phone: "+916391000414",
    email: "contact@superg.in"
}

export const SeoData = {
    title: "Wholesale Vegetables & Fruits delivery in Gorakhpur",
    decerption: "SuperG.in offers Wholesale Vegetables & Fruits delivery in Gorakhpur. Now  order Wholesale Vegetables & Fruits .",
    keyword:'Superg.in , Online Shopping in Gorakhpur ,Online Wholesale vegetables In Gorakhpur, Online Wholesale fruits In Gorakhpur',
    author:'Superg.in'
}
